export default {
  firstLandingHeadline:
    "The quick and easy multiplex method for your laboratory.",
  firstLandingSubHeadline:
    "SAFIA Technologies is developing an innovative multiplex platform for the simultaneous determination of contaminants for analytical laboratories.",
  firstlandingSecondHeadline:
    "Environmental and food monitoring require comprehensive analysis",
  landingPage03h2First:
    "Precise multiplex analyses are complicated and expensive. For laboratories, multi-analyte measurements are associated with high costs. Conventional multiplex methods take time and require highly qualified personnel. The lack of a precise, fast and cheap multiplex method often leads to incomplete environmental and food monitoring.",
  landingPage02h2:
    "Precise multiplex analyses are complicated and expensive. For laboratories, multi-analyte measurements are associated with high costs. Conventional multiplex methods take time and require highly qualified personnel. The lack of a precise, fast and cheap multiplex method often leads to incomplete environmental and food monitoring.",
  landingPage03h2:
    "We combine the advantages of different analytical methods into one product. Our rapid tests allow simple and inexpensive multiplex analysis for environmental and food monitoring applications.",
  innovation: "Innovation",
  sponsorh1: "Sponsors & Partners",
  sponsortext: "SAFIA's innovation is supported by a number of institutions.",
  innovationUSPIh1: "The SAFIA-Advantage.",
  multiAnalytMethode: `Multiplex up to\n75% Cheaper`,
  multiAnalytMethodeDesc:
    "Our innovative particles allow the performance of multiplex immunoassays. Thus, different analytes can be studied simultaneously.",
  timeSaving: `Time savings\nof up to 90%`,
  timeSavingDesc:
    "SAFIA significantly increases sample throughput and reduces time of analysis compared to conventional chromatographic methods.",
  product: "Product",
  about: "About",
  contact: "Contact",
  mixAndMeasure: `Easy\nHandling`,
  mixAndMeasureDesc:
    "Samples and reagents from the kit merely need to be mixed. The measurement is performed fully automated using a flow cytometer.",
  analyticMethods: "SAFIA & analytical methods",
  analyticMethodsFirstDesc:
    "The SAFIA (suspension array fluorescence immunoassay) technology combines  the advantages of different methods: The simple mix-and-measure immunoassay allows a fast analysis of many samples for a wide range of contaminants. With the SAFIA technology, more than 1000 individual measurements can be performed in less than 3 hours.",
  analyticMethodsSecondDesc:
    "Chromatographic methods are very expensive due to high costs for purchase, maintenance and consumables. In addition, analyses take up a lot of time and can only be carried out by highly qualified employees. Immunoassays, such as ELISA, only allow the detection of one contaminant at a time. Accordingly, they do not offer comprehensive analysis. The parallelisation of different ELISAs for multiple contaminants is often difficult.",
  safiaInNumbers: "SAFIA in numbers",
  upToSevenContaminants: "Up to 12 parallel detectable contaminants",
  safiaSystem: "The SAFIA-System.",
  upToSevenContaminantsDesc:
    "Due to the coding of our microparticles it is possible to measure several analytes simultaneously. The coding is done by gradation of a dye in the core of the particles. This encoding is detected by a flow cytometer, using only one detection channel for the 12 different particles. By combining several detection channels, the number of codes and thereby the number of analytes can be increased.",
  timeAssay: "Manual assay handling time lower than max. 35 min",
  timeAssayDesc:
    "The simple mix-and-measure technology allows the assay to be performed in less than 35 minutes. The sample only needs to be mixed with the particles and antibodies in a microtiter plate. Since SAFIA does not require any time-consuming washing steps and washing buffers, microtiter plate washers are no longer required as it is the case with conventional immunoassays.",
  readOutTime:
    "Read-out time < 45 seconds per well of a 96-well microtiter plate",
  readOutTimeDesc:
    "The analysis of the particles by the flow cytometer is fully automated using an autosampler. Our Software SAFIA-Score provides the results at the touch of a button.",
  performanceOfTheAssay: "Practical execution of SAFIA",
  extraction: "Extraction",
  extractionDesc:
    "Aqueous environmental samples can be measured directly without extraction. For food and feed samples however, the components of interest (analytes) must be extracted. For this purpose, samples must be homogenised and then mixed with the provided extraction buffer. The extracts are then divided into wells of the supplied microtiter plate. In comparison to chromatographic methods it is not necessary to do a preconcentration or a sample clean-up using pre-columns for removal of undesired sample components (“matrix”).",
  mixDesc:
    "The SAFIA particles, analyte-specific antibodies and fluorescent labelled secondary antibodies are simply mixed with the sample extracts. After incubation of antibodies, sample and particles, the reaction is stopped using a patented fixing solution. This ensures the signals of the measurements being stable during the reading of the microtiter plate. In contrast to classical immunoassays like ELISA, it is not necessary to wash between single steps.",
  measureDesc:
    "The samples in the 96 wells of the microtitre plate are read one after the other, automatically by a flow cytometer. Both, the coding of the particles and the fluoresence of bound antibodies are detected within one measurement. The evaluation of the generated data is fully automated by a tailor-made software.",
  readoutDevice: "Readout device",
  readoutDeviceDesc:
    "The integration of our system is totally carefree. We set up the system for you ready for operation.",
  softwareDesc:
    "Our tailor-made Software SAFIA-Score processes the data and generates quantitative results.",
  supportAndServiceDesc:
    "We will provide a comprehensive briefing and training of your employees. Additionally, we offer advis for your analytical questions.",
  whyIsSafiaBetter: "How does SAFIA work and why is it better?",
  whyIsSafiaBetterDesc:
    "The SAFIA, or suspension array fluorescence immunoassay, is a particle-based multiplex immunoassay. Unlike conventional immunoassays, it is performed using encoded microparticles, with exactly one analyte assigned to each code. The coding is achieved by incorporating a dye into the particles. The determination of the analytes is then performed as a competitive immunoassay. For this purpose, the surface of the microparticles is decorated with analyte molecules. These serve as anchor molecules for the antibodies. In contrast to sandwich immunoassays, only one analyte-selective antibody is needed at a time, which binds either the anchor molecules on the surface of the particles or the free analyte molecules in solution. The antibodies binding on the surface of the SAFIA particles are then stained by luminescent antibodies. All processes take place simultaneously in suspension. The actual measurement is carried out using a flow cytometer. The cytometer detects both the coding of the particles and the luminescence of the secondary antibodies. Since the flow cytometer can distinguish between free luminophores and particle-bound luminophores, SAFIA can be carried out without washing steps. Consequently, the assay can be performed much faster and easier than conventional immunoassays.\n\nTo guarantee the security of the results, we added a control measurement. With this, false-positive results are detected reliably.",
  applicationAreas: "Application areas",
  applicationAreasDesc:
    "Multiplex analysis is particularly important for food and feed monitoring: contaminants such as mycotoxins and allergens must be found as quickly and reliably as possible, as they pose a risk to consumers. The simple and fast handling of SAFIA allows many samples to be screened specifically for contaminants which themselves are only present in traces in the products. Multiplex measurements are also demanded in environmental analysis. Usually, a large number of residues must be analysed. Examples include pesticides, drug residues and antibiotics, hydrocarbons and flame retardants, which can be found in contaminated soil and water. These groups of residues each comprise many individual substances and therefore require multi-analyte measurements.",
  mycotoxinAnalysis: "Mycotoxin analysis",
  mycotoxinAnalysisDesc:
    "Mycotoxins are secondary metabolic products of moulds or ergot fungi. They are capable of causing acute poisoning, chronic diseases and even cancer when consumed. Mycotoxins represent one of the biggest contamination risks for the food industry and are therefore strongly regulated by the EU  (see Regulation (EC) No 1881/2006). In common laboratory practice, mainly chromatographic methods are currently used. This method is slow and can only be performed by specialised personnel.",
  mycotoxinAnalysisDescSecond:
    "The SAFIA Mycotoxin Kit allows the detection of six regulated mycotoxins in cereal samples (wheat, maize, barley, rye, oats, spelt) as well as in feed. Other foodstuff such as juices, nuts, oils and spices can also be tested. The limits of detection are sufficient to ensure that EU regulations are met. The detection is carried out according to the described mix-and-measure principle.",
  mycotoxinAnalysisContact: "Are you interested in our mycotoxin kit?",
  environmentalAnalysis: "Environmental analysis",
  environmentalAnalysisContact: "Are you interested in our environmental kit?",
  justContactUs: "Simply contact us!",
  environmentalAnalysisDesc:
    "SAFIA has also already proven itself in environmental analysis. We have developed a kit that can be used to analyse both drug residues (carbamazepine and diclofenac) and faecal markers (caffeine and the bile acid isolithocholic acid) in water samples. Carbamazepine and diclofenac, which are not completely degraded in sewage treatment plants, are good indicators for treated wastewater, while caffeine and bile acids are good markers for untreated wastewater. By measuring all substances simultaneously, pollution hotspots in water bodies can be identified and differentiated. In sewage treatment plants SAFIA can help to monitor the removal of drug residues and thus protect the environment.",
  allergenAnalysis: "Allergen analysis",
  allergenAnalysicDesc:
    "It is estimated that about 4% of all adults suffer from food allergies. The immune system of affected persons reacts to certain proteins called allergens. Accidental consumption of an allergen-contaminated product can cause severe reactions that can be life-threatening for those affected. Many people also suffer from food intolerances such as lactose intolerance or gluten intolerance.",
  allergenAnalysicDescTwo:
    "For this reason, the EU has decided to make food labelling mandatory (see EU regulation 1169/2011). A total of 14 different groups are currently defined that must be labelled as allergens. We will develop a kit that allows the parallel detection of different allergens in one measurement according to the mix-and-measure principle.",
  youHaveQuestions: "Do you have questions?",
  pleaseFeelFreeToContactUs: "Please feel free to contact us.",
  newsletterHeadline: "Receive the latest SAFIA News",
  newsletterSubheadline:
    "Research, development and background information. Subscribe to our newsletter and receive regular news about our SAFIA technology.",
  articles: "Articles",
  mediaCenter: "Media center",
  downloadArea: "Download section",
  loadMore: "load more",
  newsletterSubscribe: "Subscribe to newsletter",
  logoDesc:
    "Here you can download our logo for print and digital applications.",
  imprintPrivacyPolicy: "Imprint & Privacy policy",
  pressReleases: "Press releases",
  images: "Images",
  safiaFooterOne:
    "SAFIA Technologies is a spin-off of the German Federal Institute for Materials Research and Testing (BAM). The project specialises in the development of antibody-based analysis kits (immunoassays) based on SAFIA technology",
  safiaFooterTwo:
    "As a platform technology, the SAFIA technology is suitable for use in various application areas such as food, feed and environmental analysis.",
  relatedArticles: "Related articles",
  shareThisArticle: "Share this article",
  shareThisEvent: "Share this event",
  print: "Print",
  menu: "Navigation",
  event: "event",
  people: "people",
  location: "location",
  selectAll: "select all",
  lookingForwardHearing: "We are looking forward to hearing from you.",
  lookingForwardHearingDesc:
    "Schedule a personal meeting with us or simply ask for further information about our project and products.",
  contacts: "Contact",
  youCanFindUs: "You can find us at:",
  contactForm: "Contact form",
  fieldsAreRequired: "Field marked with (*) is required.",
  fieldsAreRequiredNewsletter: "Field marked with (*) is required.",
  title: "Title",
  pleaseChooseTitle: '"please choose" (Mr./Mrs.)',
  firstName: "First name",
  familyName: "Family name",
  email: "Email",
  phoneNumber: "Phone number",
  company: "Company/Institution",
  subject: "Subject",
  yourMessage: "Your message",
  acceptDataProtection: "I hereby accept the",
  dataprotection: "data protection rules",
  submit: "Submit",
  thanksContacting: "Thank you for contacting us.",
  errorContact:
    "We are sorry that we have some problems. Your message did not reach us. Please try again or contact us directly at info@safia.tech",
  required: "required",
  tooShort: "Too short",
  tooLong: "Too long",
  validEmail: "Email must be a valid email",
  message: "message",
  timelineFirst:
    "EXIST funding supports the development of a mycotoxin kit based on previous research for environmental and wastewater analysis",
  timelineFirstTitle: `Beginning of\nresearch`,
  timelineSecond:
    "R&D works on the SAFIA mycotoxin kit will be completed in the Apr 2022. Afterwards, the prototype will be ready for the piloting stage.",
  timelineSecondTitle: `Prototyp is\nready`,
  timelineThird:
    "The prototype will be finalised and commercially available by the 4nd quarter of 2022.",
  timelineThirdTitle: "Market launch mycotoxin-kit",
  timelineFourth:
    "Following the market launch of the mycotoxin kits, R&D work on a SAFIA allergen kit will start.",
  timeline_development_title: "Advancement of the SAFIA platform",
  timeline_development_desc:
    "From Q3 2023, the range of applications for SAFIA is to be expanded. Further mycotoxins are added to the SAFIA Kit and the multiplex is increased.",
  timeline_marketentry_title: "Market launch advanced mycotoxin-kit",
  timeline_marketentry_desc:
    "At the end of 2024, mycotoxin-kits will be launched for an even wider range of applications.",
  timelineFourthTitle: `Start of R&D\nallergene-kit`,
  receiveLatestNews: "Receive the latest SAFIA-News",
  receiveLatestNewsDesc:
    "Research, development and background information. Subscribe to our newsletter and receive regular news about our SAFIA technology.",
  subscribeNewsletter: "Subscribe to newsletter",
  readoutDeviceDescBenchTop:
    "You will receive a robust benchtop flow cytometer for read-out of the assay.",
  timelineMilestones: "Timeline & Milestones",
  may: "May",
  march: "March",
  july: "July",
  january: "January",
  october: "October",
  aboutTitle: "Find out more about us and our technology.",
  aboutDesc:
    "In our media center you can find articles and further information about our project.",
  bam: "c/o German Federal Institute for Materials Research and Testing (BAM)",
  quote:
    "SAFIA is an innovation with the potential to change the field of bioanalytics.",
  quoteName: "Prof. Dr. rer. nat. habil. Ulrich Panne",
  quoteTitle:
    "President of the Bundesanstalt für Materialforschung und -prüfung (BAM)",
  pleaseChoose: "Please choose",
  mr: "Mr.",
  ms: "Ms.",
  safiakit: "SAFIA Kit",
  imprintdatapolicy: "Imprint & Privacy policy",
  sharetwitter: "Share on Twitter",
  sharelinkedin: "Share on LinkedIn",
  projectdescription: "SAFIA Project outline",
  rollup: "SAFIA Rollup",
  onepager_de: "SAFIA Onepager (DE)",
  onepager_en: "SAFIA Onepager (EN)",
  kitMykotocinDesc1:
    "This multiplex analysis kit allows the parallel determination of EU regulated",
  kitAllergenDesc1:
    "The multiplex analysis kit enables the parallel determination of",
  kitUmweltDesc1: "The multiplex analysis kit for",
  kitUmweltDesc2:
    "enables the parallel determination of four anthropogenic markers in wastewater.",
  publisher: "Publisher",
  publisherDesc: `SAFIA Technologies GmbH\nc/o Bundesanstalt für Materialforschung und –prüfung (BAM)\nRichard-Willstätter-Straße 11\n12489 Berlin, Germany\n\nT: +49 162 7323 405\nM: info@safia.tech\n\nUSt-Id-Nr.: DE355156723`,
  imprint: "Imprint",
  privacyPolicy: "Privacy policy",
  legalForm: "Legal form",
  legalFormDesc:
    "BAM is a scientific and technical higher federal authority within the portfolio of the Federal Ministry of Economics and Energy.",
  registerEintrag: "Registration entry",
  registerEintragDesc: `Amtsgericht Charlottenburg\nHRB 244102 B\n\nUmsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:\nDE355156723`,
  supervisoryAuthority: "Supervisory authority",
  supervisoryAuthorityDesc: `Federal Ministry of Economics and Energy\nScharnhorststr. 34-37, 10115 Berlin\nPostal address: 11019 Berlin\nPhone: +49 30 2014-9\nfax: +49 30 2014-1070\nInternet: www.bmwi.de`,
  authorizedRepresentative: "Authorized representative:",
  authorizedRepresentativeDesc: "Dr. Peter Carl und Dr. Timm Schwaar",
  copyright: "Copyright",
  copyrightDesc: `Federal Institute for Materials Research and Testing (BAM)\nUnter dem Eichen 87, 12205 Berlin\nPostal address: 12200 Berlin\n\nTexts, parts of texts, graphics, image materials  made available on the SAFIA websites may not be reproduced, distributed or exhibited without the prior consent of BAM.\n\nThis does not apply to documents and images that are explicitly offered for download for reporting purposes. In this case, the terms of use stated in each case apply.`,
  productRequest: "Request Product",
  moreInformation: "More information",
  responsiblePerson: "I.  Responsible person and data protection officer",
  responsiblePersonDesc: `Responsible for the processing of personal data is\n\nSAFIA Technologies GmbH\nc/o Bundesanstalt für Materialforschung und –prüfung (BAM)\nRichard-Willstätter-Straße 11\n12489 Berlin, Germany\n\nemail: info(at)safia.tech\n\nIf you have specific questions regarding the protection of your data, please contact data protection officer:\n\nDr. Timm Schwaar\nE-Mail: schwaar(at)safia.tech`,
  generalInformation: "II.  General information",
  generalInformationDesc:
    "The protection of your privacy and the protection of your personal data is an important concern for us. Accordingly, we inform you in the following sections on the types of personal data, which are collected when using our website. Personal data is defined as all data that can be related to you personally, e.g. name, address, email addresses, user behaviour.",
  processingActivities: "III. Processing activities",
  operationWebsite: "1.	Operation of the website",
  provisionWebsite: "a.	Provision and use of the website",
  provisionDesc:
    "For the hosting of our website, back-up services and data storage, we use the services of STRATO AG (hereinafter referred to as STRATO). Within the scope of processing on our behalf, STRATO provides the services for hosting, back-up, security, computing capacity and display of the website. We have concluded a contract with STRATO for processing on our behalf.",
  stratoDataPolicy:
    "STRATO's privacy policy is available at: https://www.strato.de/datenschutz/",
  stratoDataPolicyDesc:
    "In general, it is not necessary for you to provide personal data in order to use the www.safia.tech website. In order to collect access statistics to the internet page, STRATO automatically collects and stores the following data in log files on the server, transmitted by the browser.",
  dataBulletPointDesc:
    "The following type of data are collected. Information about:",
  dataBulletPointType: "the browser type and the version used",
  dataBulletPointSystem: "the user's operating system",
  dataBulletPointIP: "the IP address of the user",
  dataBulletPointTime: "date and time of access",
  dataBulletPointRefere:
    "websites from which the user's system reaches our website",
  dataBulletPointAccess:
    "websites that are accessed by the user's system via our website",
  dataBulletPointStatusCode: "status code of the call",
  dataBulletPointHTTPVersion: "HTTP protocol version",
  dataBulletPointDataSize: "data size when calling the resource.",
  descSecurity:
    "This processing is technically necessary in order to display our website. We also use the data to ensure the security and stability of our website. The IP addresses are stored for a maximum of seven days to detect and defend against attacks and are then anonymised or deleted.",
  useOfCookies: "b. Use of cookies",
  useOfCookiesDesc1: "Our website uses cookies.",
  useOfCookiesDesc2:
    "Cookies are small text files which are stored and through which SAFIA Technologies GmbH receives certain information. Cookies cannot run programs or transfer viruses to your computer. They are stored on the user's computer and send information from it to our site. Therefore, you as a user have full control over the use of cookies. By changing the settings in your Internet browser, you can deactivate or restrict the transmission of cookies.",
  useOfCookiesDesc3:
    "However, cookies are used to make the website more user-friendly and effective overall.",
  useOfCookiesDesc4:
    "We also use cookies on our website, which enable an analysis of surfing behaviour of users (see web analysis by Matomo).",
  useOfCookiesDesc5:
    "However, the protection of your data is important to us! Therefore, we have configured all cookies in such a way that only a session ID is stored, which is automatically deleted after 31 days.",
  webAnalysisMatomo: "c.  Web analysis by Matomo",
  webAnalysisMatomoDesc1:
    'For statistical analysis SAFIA Technologies GmbH uses "Matomo" (formerly "PIWIK") on this website. This is an open source tool for web analysis. With Matomo, no data is transferred to servers that are outside the control of SAFIA Technologies GmbH. The software uses cookies (for cookies see above).',
  webAnalysisMatomoDesc2:
    "To protect your data, we have additionally configured Matomo in a way that your IP address is only recorded in abbreviated form. Hence, we process your personal user data anonymously. It is not possible for us to draw conclusions about your person. Further information on the Matomo terms of use and the data protection regulations can be found at: https://matomo.org/privacy/.",
  legalBasis: "d. Legal basis",
  legalBasisDesc:
    "The legal basis for the provision of the website www.safia.tech is section 6.1 f) GDPR. The processing of the data mentioned above is necessary for the provision of this website and thus serves to safeguard a legitimate interest of SAFIA Technologies GmbH.",
  durationOfStorage: "e.  Duration of storage",
  durationOfStorageDesc:
    "The data will be deleted as soon as they are no longer necessary for the purpose for which they were collected. In the case of the collection of data for the purpose of providing the website, this is the case when the relevant session has ended. In the case of storage of data in log files, this is the case after 90 days at the latest. Storage beyond this period is possible. In this case, the IP addresses of the users are deleted or alienated so that it is no longer possible to assign the calling client.",
  appeal: "f. Possibilities of appeal",
  appealDesc1:
    "There is no possibility of objection with the exception of the web analysis by Matomo according to c.",
  appealDesc2:
    "The web analysis by Matomo according to c. can be objected at any time with effect for the future by using the button below.",
  contactFormAndEmail: "2.  Contact form and email contact",
  contactFormAndEmailDesc1:
    "You can contact SAFIA Technologies GmbH using the contact form provided on the website. The data entered in the input mask as well as the date and time of contacting are transmitted and stored.",
  contactFormAndEmailDesc2:
    "Alternatively, it is possible to contact us via the email address provided. In this case, the user's personal data transmitted with the email will be stored.",
  contactFormAndEmailDesc3:
    "The processing of the personal data from the contact form or email contact serves exclusively to process the respective enquiry.",
  contactFormAndEmailDesc4:
    "The legal basis for this processing is section 6.1 f) GDPR. The processing of the above-mentioned data is necessary for the purpose of responding to requests and thus serves a legitimate interest of SAFIA Technologies GmbH.",
  contactFormAndEmailDesc5:
    "The personal data from the input mask of the contact form and those sent by email are deleted when they are no longer required. The additional personal data collected during the sending process will be deleted after a period of seven days at the latest.",
  contactFormAndEmailDesc6:
    "If the user contacts us by email, he can object to the storage of his personal data at any time. In such a case the conversation cannot be continued. All personal data stored during the ongoing communication will be deleted in this case.",
  emailDistributionList: "3.  Email distribution list",
  emailDistributionListDesc1:
    "On our website there is a registration form which can be used to register to a newsletter.",
  emailDistributionListDesc2:
    'The personal data entered here is stored for processing in a data system on a server of a third party, in this case the newsletter service provider "Sendinblue".',
  emailDistributionListDesc3:
    "In this way, those responsible for the SAFIA third-party funded project will be able to send information manually or automatically to registered email addresses.",
  emailDistributionListDesc4: "Types of processed data are:",
  emailDistributionListLI1: "title (optional)",
  emailDistributionListLI2: "first name (optional)",
  emailDistributionListLI3: "last name (optional)",
  emailDistributionListLI4: "email",
  emailDistributionListDesc5:
    "The processing and saving of personal data from the input mask is only used to save the data in a newsletter backend system for later automatic sending of emails to the registered email address.",
  emailDistributionListDesc6:
    "The legal basis for the storage of data entered while subscribing to the newsletter is section 6.1 a) GDPR.",
  emailDistributionListDesc7:
    "The data will be deleted as soon as they are no longer required for the purpose of automated sending of emails. This is the case when the duration of the SAFIA project expires and the project is not to be continued as a spin-off (private company).",
  emailDistributionListDesc8: `The user has the possibility to revoke his consent to the storage of personal data at any time. The user can do this via an unsubscribe link, which can be found at the end of an email that was sent via the newsletter backend system. In this case, the user's email address is removed from the newsletter system and the user's personal data is deleted. Further information on the terms of use of "Sendinblue" and the data protection regulations can be found at: https://de.sendinblue.com/legal/privacypolicy/.`,
  rightsDataSubject: "IV. Rights of the data subject",
  rightsDataSubjectDesc1:
    "You have the following rights regarding personal data concerning you:",
  rightsDataSubjectLI1: "Right to withdraw consent, section 13 and 14",
  rightsDataSubjectLI2: "Right to information - section 15 GDPR",
  rightsDataSubjectLI3: "Right of rectification - section 16 GDPR",
  rightsDataSubjectLI4: "Right of cancellation - section 17 GDPR",
  rightsDataSubjectLI5: "Right to restrict processing - section 18 GDPR",
  rightsDataSubjectLI6: "Right to data transferability - section 20 GDPR",
  rightsDataSubjectLI7: "Right to object - section 21 GDPR",
  rightsDataSubjectDesc2:
    "You can assert these rights against the person responsible mentioned under A.",
  rightsDataSubjectDesc3:
    "According to section 77 of the GDPR you also have a right of appeal to the supervisory authority under data protection law, the Federal Commissioner for Data Protection and Freedom of Information (BfDI).",
  rightsDataSubjectDesc4:
    "You can also contact the BAM data protection officer mentioned under B with questions and complaints.",
  rightsDataSubjectDesc5:
    "Further information on data protection can be found on BAM's data protection declaration at https://www.bam.de/Navigation/DE/Services/Datenschutz/datenschutz.html",
  weAreHere: "We are here",
  newslettersuccess:
    "Thank you. Please confirm your subscription via the link we sent you by email.",
  newslettererror: "Oops, there was a mistake. Please try it again.",
  isLoading: "sending ...",
  isLoadingNewsletter: "subscribing ...",
  newsletterconfirm: "You have successfully registered for the newsletter",
  newslettersuccessseo: "Newsletter registration successful",
  error404desc: "We are sorry, but the requested page could not be found!",
  divers: "Mx.",
  noSimilarReports: "There are no similar posts.",
  understand: "I understand",
  cookieText:
    "To provide you with the best user experience, we use cookies. By using this website, you accept our",
  datenschutzrichtlinien: "privacy policy.",
  umweltanalytikHref: "environmental analysis",
  schimmelpilzgifteHref: "mycotoxins",
  allergenenHref: "allergens",
  pressReleaseEmpty: "Currently there are no press releases yet.",
  blogEmpty: "Currently there are no articles yet.",
  videos: "Videos",
  ceo: "CEO",
  timeline: {
    first: {
      title: "Research & Development\nbeginns",
      description:
        "EXIST funding supports the development of a mycotoxin kit (link to mycotoxins) based on previous research for environmental and wastewater analysis (link to environmental analysis) April 2020",
    },
    second: {
      title: "Prototyp\nis ready",
      description:
        "R&D works on the SAFIA mycotoxin kit will be completed in the Apr 2022. Afterwards, the prototype will be ready for the piloting stage.",
    },
    third: {
      title: "Market launch mycotoxin-kit",
      description:
        "The prototype will be finalised and commercially available by the 4nd quarter of 2022. ",
    },
    fourth: {
      title: "Advancement of the SAFIA platform",
      description:
        "From Q2 2023, the range of applications for SAFIA is to be expanded. Further mycotoxins are added to the SAFIA Kit.",
    },
    fifth: {
      title: "Market launch advanced mycotoxin-kit",
      description:
        "At the end of 2024, mycotoxin-kits will be launched for an even wider range of applications.",
    },
    sixth: {
      title: "Start of\nR&D allergene-kit",
      description:
        "Following the market launch of the mycotoxin-kits, R&D work on a SAFIA allergen kit will start.",
    },
  },
};
