import React, { useMemo, useState, useEffect } from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationDE from "./src/i18n/locales/de/translations";
import translationEN from "./src/i18n/locales/en/translations";
import { LanguageContext } from "./src/context";

const App = ({ element }) => {
  const windowGlobal = typeof window !== "undefined" && window;
  const [currentLang, setCurrentLang] = useState("DE");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let languageFromStore = windowGlobal.localStorage.getItem("language");
    if (languageFromStore === null) {
      languageFromStore = "DE";
    }
    setCurrentLang(languageFromStore);
    i18n.use(initReactI18next).init({
      resources: {
        de: {
          translation: translationDE,
        },
        en: {
          translation: translationEN,
        },
      },
      lng: languageFromStore.toLowerCase(),
      fallbackLng: "de",
      debug: true,

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });
    setLoading(false);
  }, []);

  const languageContext = useMemo(
    () => ({
      changeLanguage: (payload) => {
        setCurrentLang(payload);
        windowGlobal.localStorage.setItem("language", payload);
      },
      getLanguage: () => {
        return currentLang;
      },
    }),
    [currentLang]
  );
  if (loading) return null;
  return (
    <LanguageContext.Provider value={languageContext}>
      {element}
    </LanguageContext.Provider>
  );
};

const Wrapper = ({ element }) => {
  return <App element={element} />;
};

export default Wrapper;
