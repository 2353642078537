module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SAFIA Technologies GmbH","short_name":"SAFIA","description":"SAFIA Technologies GmbH ist ein Spin-Off der Spin-off der Bundesanstalt für Materialforschung und –prüfung (BAM). Das Unternehmen wurde im Sommer 2022 von Peter Carl und Timm Schwaar gegründet. Wir haben uns darauf spezialisiert antikörperbasierten Analysekits (Immunoassays) auf Grundlage der SAFIA-Technologie zu entwickeln.","start_url":"/","background_color":"#f7f0eb","theme_color":"#69FF75","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1f936e1384796ea574b9c4c8a6ea2002"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://analytics.safia.tech","siteUrl":"https://safia.tech"},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/home/runner/work/safia-web/safia-web/src/cms/cms.js","htmlTitle":"Willkommen im Safia-Backend","htmlFavicon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":768,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
