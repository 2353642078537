export default {
  firstLandingHeadline:
    "Die schnelle und einfache Multiplex-Methode für Ihr Labor.",
  firstLandingSubHeadline:
    "SAFIA Technologies entwickelt eine innovative Multiplex-Plattform für die simultane Bestimmung von Kontaminanten im analytischen Laborbetrieb.",
  firstlandingSecondHeadline:
    "Umwelt- und Lebensmittelmonitoring erfordern umfassende Analytik",
  landingPage02h2:
    "Präzise Multiplexanalysen sind kompliziert und teuer. Für Labore sind Multi-Analyt-Messungen mit hohen Kosten verbunden. Die Durchführung der üblichen Methoden nimmt sehr viel Zeit in Anspruch und benötigt hochqualifiziertes Personal. Der Mangel einer präzisen, schnellen und günstigen Multiplexmethode führt oft zu einer lückenhaften Umwelt- und Lebensmittelüberwachung.",
  landingPage03h2First:
    "Präzise Multiplex-Analysen sind kompliziert und teuer. Für Labore sind Multi-Analyt-Messungen mit hohen Kosten verbunden. Die Durchführung der üblichen Methoden nimmt sehr viel Zeit in Anspruch und benötigt hochqualifiziertes Personal. Der Mangel einer präzisen, schnellen und günstigen Multiplex-Methode führt oft zu einer lückenhaften Umwelt- und Lebensmittelüberwachung.",
  landingPage03h2:
    "Wir verbinden die Vorteile verschiedener analytischer Methoden in einem Produkt. Unsere Schnelltests erlauben eine einfache und günstige Multiplex-Messung für Anwendungen im Umwelt- und Lebensmittelmonitoring.",
  sponsorh1: "Förderer & Partner",
  sponsortext:
    "Die Innovation von SAFIA wird durch eine Reihe von Institutionen unterstützt.",
  innovationUSPIh1: "Die SAFIA-Vorteile.",
  multiAnalytMethode: `Multiplex bis zu\n75 % günstiger`,
  multiAnalytMethodeDesc:
    "Unsere innovativen Partikel erlauben die Durchführung eines Multiplex-Immunoassays, d.h. es können verschiedene Analyten gleichzeitig untersucht werden.",
  timeSaving: `Zeitersparnis\nbis zu 90%`,
  timeSavingDesc:
    "Mithilfe von SAFIA wird der Probendurchsatz deutlich erhöht und Analysezeiten im Vergleich zu gängigen chromatographischen Methoden gesenkt.",
  innovation: "Innovation",
  mixAndMeasure: `Einfache\nHandhabung`,
  product: "Produkt",
  about: "Über uns",
  articles: "Berichte",
  contact: "Kontakt",
  safiaSystem: "Das SAFIA-System.",
  mixAndMeasureDesc:
    "Proben und Reagenzien aus dem Kit müssen lediglich miteinander vermischt werden. Die Messung erfolgt vollautomatisch in einem Durchflusszytometer.",
  analyticMethods: "SAFIA & analytische Methoden",
  analyticMethodsFirstDesc:
    "Die SAFIA-Technologie (Suspensionsarray Fluoreszenzimmunoassay) vereint die Vorteile verschiedener Verfahren: Der einfache Mix-and-Measure Immunoassay erlaubt eine schnelle Untersuchung vieler Proben auf unterschiedlichste Kontaminationen. Mit der SAFIA-Technologie können mehr als 1000 Einzelmessungen in weniger als 3 Stunden durchgeführt werden.",
  analyticMethodsSecondDesc:
    "Chromatografische Verfahren sind hingegen aufgrund hoher Anschaffungs-, Wartungs- und Verbrauchsmittelkosten sehr teuer. Zudem nehmen Analysen viel Zeit in Anspruch und können nur von hochqualifiziertem Personal durchgeführt werden. Immunoassays, wie der ELISA, erlauben lediglich den Nachweis eines Kontaminanten und bieten daher keine umfassende Analyse. Die Parallelisierung unterschiedlicher ELISAs für mehrerer Kontaminanten ist oft umständlich.",
  safiaInNumbers: "SAFIA in Zahlen",
  upToSevenContaminants: "Bis zu 12 parallel bestimmbare Kontaminanten",
  upToSevenContaminantsDesc:
    "Durch die Kodierung unserer Mikropartikel ist es möglich mehrere Analyten gleichzeitig zu messen. Die Kodierung erfolgt dabei durch Abstufung eines Farbstoffes im Kern der Partikel. Dieser wird im Durchflusszytometer erfasst, dabei wird nur ein Detektionskanal für die 12 unterschiedlichen Partikel verwendet. Durch die Kombination mehrerer Detektionskanäle können sogar ein Vielfaches an Kodierungen generiert und somit zusätzliche Analyten gemessen werden.",
  timeAssay: "Durchführungszeit Assay max. 35 min",
  timeAssayDesc:
    "Die einfache Mix-and-Measure Technologie ermöglicht die Durchführung des Assays in weniger als 35 Minuten. Die Probe muss nur mit den Partikeln und den Antikörpern in einer Mikrotiterplatte gemischt werden. Da SAFIA gänzlich ohne zeitraubende Waschschritte und Waschpuffer auskommt, werden im Vergleich zu herkömmlichen Immunoassays keine Geräte für das Waschen der Mikrotiterplatten mehr benötigt.",
  readOutTime:
    "Auslesezeit < 45 Sekunden pro Kavität einer 96-Well Mikrotiterplatte",
  readOutTimeDesc:
    "Die Analyse der Partikel erfolgt vollautomatisch im Durchflusszytometer mithilfe eines Autosamplers. Dank unserer Software SAFIA-Score gelingt die Auswertung auf Knopfdruck.",
  performanceOfTheAssay: "Durchführung des SAFIA",
  extraction: "Extraktion",
  extractionDesc:
    "Wässrige Umweltproben können direkt ohne Extraktion gemessen werden. Aus Lebens- und Futtermittelmittelproben müssen zunächst die gesuchten Probenbestandteile (Analyten) extrahiert werden. Dafür müssen die Proben homogenisiert und anschließend mit dem bereitgestellten Extraktionspuffer vermischt werden. Die Extrakte werden dann in Kavitäten der mitgelieferten Mikrotiterplatte aufgeteilt. Im Vergleich zu chromatografischen Verfahren müssen die übrigen Probenbestandteile („Matrix“) nicht über eine Vorsäule abgetrennt oder die Probe gar aufkonzentriert werden.",
  mixDesc:
    "Zu den Probenextrakten werden lediglich die SAFIA-Partikel, Analyt-spezifische Antikörper und markierte sekundäre Antikörper hinzugegeben und gemischt. Nach erfolgter Inkubation der Antikörper, Probe und Partikel wird die Reaktion mit einer patentierten Fixierlösung gestoppt. Dadurch wird sichergestellt, dass die Signale der Messungen während der Auslesung der Mikrotiterplatte stabil sind. Im Gegensatz zu klassischen Immunoassays, wie ELISA, ist es dabei nicht nötig zwischendurch zu waschen.",
  measureDesc:
    "Die Proben in den 96 Kavitäten der Mikrotiterplatte werden nacheinander und automatisch von einem Durchflusszytometer ausgelesen. Bei der Messung werden sowohl die Kodierung der Partikel als auch die gebundenen Antikörper detektiert. Die Auswertung der erzeugten Daten erfolgt vollkommen automatisch durch eine maßgeschneiderte Software.",
  readoutDevice: "Auslesegerät",
  readoutDeviceDesc:
    "Sie müssen sich um nichts sorgen. Wir richten Ihnen das System betriebsfertig ein.",
  softwareDesc:
    "Unsere maßgeschneiderte Software - SAFIA-Score - übernimmt die Verarbeitung der Gerätedaten und erzeugt die Ergebnisse.",
  supportAndServiceDesc:
    "Sie erhalten eine Einweisung in das SAFIA Systemund eine Fortbildung Ihrer Mitarbeiter. Wir beraten Sie gerne bei Ihreren analytischen Fragestellungen.",
  whyIsSafiaBetter: "Wie funktioniert SAFIA und warum ist SAFIA besser? ",
  whyIsSafiaBetterDesc:
    "Der SAFIA, oder Suspensionsarray Fluoreszenzimmunoassay, ist ein partikelbasierter Multiplex Immunoassay. Im Gegensatz zu konventionellen Immunoassays wird dieser auf kodierten Mikropartikeln durchgeführt, wobei jedem Kode genau ein Analyt zugeordnet wird. Erreicht wird die Kodierung durch Einbau eines Farbstoffes in die Partikel. Die Bestimmung der Analyten erfolgt dann als kompetitiver Immunoassay. Hierfür wird die Oberfläche der Mikropartikel mit Analytmolekülen dekoriert. Diese dienen als Ankermoleküle für die Antikörper. Im Gegensatz zu Sandwich Immunoassays wird jeweils nur ein analytselektiver Antikörper eingesetzt, der entweder die Ankermoleküle auf der Oberfläche der Partikel oder die freien Analytmoleküle in Lösung bindet. Die sich an der Oberfläche der SAFIA Partikel bindenden Antikörper werden dann durch lumineszierende Antikörper angefärbt. Alle Prozesse finden gleichzeitig in der Suspension statt. Die eigentliche Messung erfolgt mithilfe eines Durchflusszytometers. Dieses erfasst sowohl die Kodierung der Partikel als auch die Lumineszenz der sekundären Antikörper. Da mithilfe des Durchflusszytometers zwischen freien Luminophoren und partikelgebundenen Luminophoren unterschieden werden kann, kann SAFIA ohne Waschschritte durchgeführt werden. Damit ist der Assay deutlich schneller und einfacher in der Durchführung als konventionelle Immunoassays.\n\nUm die Sicherheit der Ergebnisse zu garantieren, haben wir zusätzlich eine Kontrollmessung eingebaut. Mit dieser können zuverlässig falsch-positive Ergebnisse erkannt werden. ",
  applicationAreas: "Einsatzbereiche",
  applicationAreasDesc:
    "Multiplexmessungen sind vor allem bei der Analyse von Lebens- und Futtermitteln wichtig: Hier müssen Kontaminanten, wie Mykotoxine und Allergene, möglichst schnell und sicher gefunden werden, da sie eine Gefahr für Verbraucher darstellen. Durch die einfache und schnelle Durchführung von SAFIA können viele Proben gezielt nach Kontaminanten gescreent werden, die selbst nur in Spuren in den Produkten vorkommen. Auch in der Umweltanalytik sind Multiplexanalysen gefragt. Oft müssen in Gewässer- und Bodenproben eine Vielzahl von Rückständen analysiert werden. Beispiele hierfür sind Pestizide, Medikamentenrückstände und Antibiotika, aber auch Altlasten, wie Kohlenwasserstoffe und Flammschutzmittel. Diese Gruppen an Rückständen umfassen jeweils viele einzelne Stoffe und benötigen deshalb Multianalytmessungen. ",
  mycotoxinAnalysis: "Mykotoxinanalytik",
  mycotoxinAnalysisDesc:
    "Mykotoxine sind sekundäre Stoffwechselprodukte von Schimmel- oder Mutterkornpilzen. Diese lösen bei Verzehr akute Vergiftungen aus. Sie können auch chronische Krankheiten verursachen und sogar Krebs auslösen. Mykotoxine stellen für die Lebensmittelindustrie eines der größten Kontaminationsrisiken dar und werden daher von der EU stark reguliert siehe Verordnung (EG) Nr. 1881/2006). In der Laborpraxis kommen derzeit hauptsächlich chromatographische Methoden zum Einsatz. Diese Methode ist langsam und kann nur durch spezialisiertes Personal durchgeführt werden.",
  mycotoxinAnalysisDescSecond:
    "Mithilfe des SAFIA-Mykotoxin Kits lassen sich sechs regulierte Mykotxine sowohl in Getreideproben (Weizen, Mais, Gerste, Roggen, Hafer, Dinkel) als auch in Futtermitteln nachweisen. Auch Lebensmittel wie Säfte, Nüsse, Öle und Gewürze können getestet werden. Die Nachweisempfindlichkeit ist dabei ausreichend, um sicherzustellen, dass die EU-Grenzwerte eingehalten werden. Der Nachweis erfolgt nach dem beschriebenen Mix-and-Measure Prinzip.",
  mycotoxinAnalysisContact: "Sie haben Interesse an unserem Mykotoxin-Kit?",
  environmentalAnalysis: "Umweltanalytik",
  environmentalAnalysisContact: "Sie haben Interesse an unserem Umwelt-Kit?",
  justContactUs: "Kontaktieren Sie uns einfach!",
  environmentalAnalysisDesc:
    "Auch in der Umweltanalytik konnte sich SAFIA bereits bewähren. Wir haben einen Kit entwickelt, mit dem sowohl Medikamentenrückstände (Carbamazepin und Diclofenac) und Fäkalmarker (Koffein und die Gallensäure Isolithocholsäure) in Wasserproben analysiert werden können. Carbamazepin und Diclofenac, die in Kläranlagen nicht vollständig abgebaut werden, sind gute Indikatoren für gereinigte Abwässer, während Coffein und Gallensäuren gute Marker für ungeklärte Abwässer sind. Durch das gleichzeitige Messen aller Substanzen können Verschmutzungs-Hotspots in Gewässern ermittelt und differenziert werden. In Kläranalagen kann SAFIA helfen, die Entfernung von Medikamentenrückständen zu Überwachen und damit die Umwelt zu schützen.",
  allergenAnalysis: "Allergenanalytik",
  allergenAnalysicDesc:
    "Es wird geschätzt, dass etwa 4 % aller Erwachsenen an einer Lebensmittelallergie leiden. Dabei reagiert das Immunsystem von Betroffenen auf bestimmte Proteine, die man als Allergene bezeichnet. Der unbeabsichtigte Verzehr eines mit Allergenen  kontaminierten Produkts kann schwere Reaktionen auslösen, die für Betroffene lebensgefährlich sein können. Daneben leiden viele Menschen an Nahrungsmittelunverträglichkeiten wie z. B. der Laktoseintoleranz oder Glutenunverträglichkeit.",
  allergenAnalysicDescTwo:
    "Daher hat die EU eine Lebensmittelkennzeichnungspflicht erlassen (siehe EU-Verordnung 1169/2011). In dieser werden insgesamt 14 unterschiedliche Gruppen definiert, die als Allergene gekennzeichnet werden müssen. Wir entwickeln ein Kit, mit dem der parallele Nachweis verschiedener Allergene in einer Messung nach dem Mix-and-Measure Prinzip möglich wird.",
  youHaveQuestions: "Sie haben Fragen?",
  pleaseFeelFreeToContactUs: "Kontaktieren Sie uns gerne.",
  newsletterHeadline: "SAFIA-News in Ihr Postfach",
  newsletterSubheadline:
    "Forschung, Entwicklung und Hintergründe. Abonnieren Sie unseren Newsletter und erhalten Sie regelmäßig Neuigkeiten rund um die SAFIA-Technologie.",
  mediaCenter: "Media Center",
  downloadArea: "Downloads",
  loadMore: "Mehr laden",
  newsletterSubscribe: "Newsletter abonnieren",
  logoDesc:
    "Hier können Sie unser Logo für Print und digitale Anwendungen downloaden.",
  imprintPrivacyPolicy: "Impressum & Datenschutz",
  pressReleases: "Pressemitteilungen",
  images: "Bilder",
  safiaFooterOne:
    "SAFIA Technologies GmbH ist ein Spin-off der Bundesanstalt für Materialforschung und –prüfung (BAM). Das Unternehmen wurde im Sommer 2022 von Peter Carl und Timm Schwaar gegründet. Wir haben uns darauf spezialisiert antikörperbasierten Analysekits (Immunoassays) auf Grundlage der SAFIA-Technologie zu entwickeln.",
  safiaFooterTwo:
    "Als Plattformtechnologie eignet sich die SAFIA-Technologie für den Einsatz in verschiedenen Anwendungsbereichen wie der Lebensmittel-, Futtermittel- und der Umweltanalytik.",
  relatedArticles: "Ähnliche Berichte",
  shareThisArticle: "Diesen Bericht teilen",
  shareThisEvent: "Dieses Event teilen",
  print: "Drucken",
  menu: "Menü",
  event: "Event",
  people: "People",
  location: "Location",
  selectAll: "Alle",
  lookingForwardHearing: "Wir freuen uns, von Ihnen zu hören.",
  lookingForwardHearingDesc:
    " Vereinbaren Sie einen unverbindlichen Beratungstermin oder stellen Sie ganz einfach Ihre Fragen zu unserem Projekt und Produkten.",
  contacts: "Ihre Ansprechpartner",
  youCanFindUs: "Hier können Sie uns finden:",
  contactForm: "Kontaktformular",
  fieldsAreRequired:
    "Damit wir Ihre Anfrage beantworten können, müssen die mit (*) markierten Felder ausgefüllt werden.",
  fieldsAreRequiredNewsletter:
    "Das mit (*) markierte Feld muss ausgefüllt sein.",
  title: "Anrede",
  pleaseChooseTitle: '"Bitte wählen" (Herr/Frau)',
  firstName: "Vorname",
  familyName: "Nachname",
  email: "E-Mail",
  phoneNumber: "Telefon",
  company: "Firma/Institution",
  subject: "Betreff",
  yourMessage: "Ihre Nachricht",
  message: "Nachricht",
  acceptDataProtection: "Hiermit akzeptiere ich die",
  dataprotection: "Datenschutzbestimmmungen",
  submit: "Absenden",
  thanksContacting: "Danke, dass Sie uns kontaktiert haben.",
  errorContact: "Ihre Nachricht konnte leider nicht gesendet werden!",
  required: "erforderlich",
  tooShort: "Zu kurz",
  tooLong: "Zu lang",
  validEmail: "E-Mail muss eine gültige E-Mail sein",
  timelineFirstTitle: `Beginn der\nForschung`,
  timelineFirst:
    "Eine EXIST-Förderung unterstützt die Weiterentwicklung des Assays zu einem Mykotoxin-Kit.",
  timelineSecondTitle: "Fertigstellung des Prototypen",
  timelineSecond:
    "Ab April 2022 sollen die FuE-Arbeiten beendet werden und der Prototyp für die Pilotierungsphase bereitstehen.",
  timelineThirdTitle: "Markteintritt Mykotoxin-Kit",
  timelineThird:
    "Der Prototyp soll bis Q4 2022 finalisiert werden und ab diesem Zeitpunkt kommerziell erhältlich sein.",
  timelineFourthTitle: `Beginn F&E\nAllergen-Kit`,
  timelineFourth:
    "In Anschluss an den Market-Launch der Mykotoxin-Kits sollen die FuE Arbeiten für ein SAFIA Allergen-Kit beginnen.",
  timeline_development_title: "Weiterentwicklung SAFIA Plattform",
  timeline_development_desc:
    "Ab Q3 2023 soll das Applikationssprektrum von SAFIA erweitert werden. Dem SAFIA Kit werden weitere Mykotoxine hinzugefügt und dafür der Multiplex vergrößert.",
  timeline_marketentry_title: "Markteintritt erweitertes Mykotoxin-Kit",
  timeline_marketentry_desc:
    "Ende 2024 werden Mykotoxin-Kits für ein noch breiteres Anwendungsfeld auf den Markt gebracht.",
  receiveLatestNews: "SAFIA-News in Ihr Postfach",
  receiveLatestNewsDesc:
    "Forschung, Entwicklung und Hintergründe. Abonnieren Sie unseren Newsletter und erhalten Sie regelmäßig Neuigkeiten rund um die SAFIA-Technologie.",
  subscribeNewsletter: "Newsletter abonnieren",
  readoutDeviceDescBenchTop:
    "Sie erhalten ein robustes Benchtop-Durchflusszytometer für die Auslesung des Assays.",
  timelineMilestones: "Zeitplan & Milestones",
  may: "Mai",
  march: "März",
  july: "Juli",
  january: "Januar",
  october: "Oktober",
  aboutTitle: "Alles über uns und unsere Technologie.",
  bam: "c/o Bundesanstalt für Materialforschung und –prüfung (BAM)",
  aboutDesc:
    "In unserem Media Center finden Sie Beiträge und Informationen zum Projekt, unseren Produkten und zur Multiplex-Analytik.",
  quote:
    "Die SAFIA-Innovation hat das Potenzial, das Feld der Bioanalytik neu zu gestalten.",
  quoteName: "Prof. Dr. rer. nat. habil. Ulrich Panne",
  quoteTitle:
    "Präsident der Bundesanstalt für Materialforschung und -prüfung (BAM)",
  pleaseChoose: "Bitte wählen",
  mr: "Herr",
  ms: "Frau",
  safiakit: "SAFIA-Kit",
  kitMykotocinDesc1:
    "Das Multiplex-Analyse-Kit ermöglicht die parallele Bestimmung der EU-regulierten",
  kitAllergenDesc1:
    "Das Multiplex-Analyse-Kit ermöglicht die parallele Bestimmung von",
  kitUmweltDesc1: "Das Multiplex-Analyse-Kit für die",
  kitUmweltDesc2:
    "ermöglicht die parallele Bestimmung von vier anthropogenen Markern in Abwässern.",
  imprintdatapolicy: "Impressum & Datenschutz",
  sharetwitter: "Auf Twitter teilen",
  sharelinkedin: "Auf LinkedIn teilen",
  projectdescription: "SAFIA Projektbeschreibung",
  rollup: "SAFIA Rollup",
  onepager_de: "SAFIA Onepager (DE)",
  onepager_en: "SAFIA Onepager (EN)",
  publisher: "Herausgeber",
  publisherDesc: `SAFIA Technologies GmbH\nc/o Bundesanstalt für Materialforschung und –prüfung (BAM)\nRichard-Willstätter-Straße 11\n12489 Berlin, Germany\n\nT: +49 162 7323 405\nM: info@safia.tech\n\nUSt-Id-Nr.: DE355156723`,
  legalForm: "Rechtsform",
  registerEintrag: "Registereintrag",
  registerEintragDesc: `Amtsgericht Charlottenburg\nHRB 244102 B\n\nUmsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:\nDE355156723`,
  legalFormDesc:
    "Die BAM ist eine wissenschaftlich-technische Bundesoberbehörde im Geschäftsbereich des Bundesministeriums für Wirtschaft und Energie.",
  imprint: "Impressum",
  privacyPolicy: "Datenschutz",
  supervisoryAuthority: "Aufsichtsbehörde",
  supervisoryAuthorityDesc: `Bundesministerium für Wirtschaft und Energie\nScharnhorststr. 34-37, 10115 Berlin\nPostadresse: 11019 Berlin\nTelefon: +49 30 2014-9\nFax: +49 30 2014-1070\nInternet: www.bmwi.de`,
  authorizedRepresentative: "Vertretungsberechtigte Person:",
  authorizedRepresentativeDesc: "Dr. Peter Carl und Dr. Timm Schwaar",
  copyright: "Copyright",
  copyrightDesc: `Bundesanstalt für Materialforschung und -prüfung (BAM)\nUnter dem Eichen 87, 12205 Berlin\nPostanschrift: 12200 Berlin\n\nAuf der SAFIA-Website zur Verfügung gestellte Texte, Textteile, Grafiken, Bildmaterialien  dürfen ohne vorherige Zustimmung der BAM nicht vervielfältigt, nicht verbreitet und nicht ausgestellt werden.\n\nAusgenommen sind Dokumente, die explizit zur Berichterstattung als Download angeboten werden. Hier gelten die jeweils angegebenen Nutzungsbedingungen.`,
  productRequest: "Produkt anfragen",
  moreInformation: "Mehr Informationen",
  responsiblePerson: "I.  Verantwortlicher und Datenschutzbeauftragter",
  responsiblePersonDesc: `Verantwortlich für die Verarbeitung von personenbezogenen Daten ist die\n\nSAFIA Technologies GmbH\nc/o Bundesanstalt für Materialforschung und –prüfung (BAM)\nRichard-Willstätter-Straße 11\n12489 Berlin, Germany\n\ne-mail: info(at)safia.tech\n\nBei konkreten Fragen zum Schutz Ihrer Daten wenden Sie sich bitte an unseren Datenschutzbeauftragten:\n\nDr. Timm Schwaar\nE-Mail: schwaar(at)safia.tech`,
  generalInformation: "II.  Allgemeines",
  generalInformationDesc:
    "Wir freuen uns, dass Sie unsere Webseite besuchen. Der Schutz Ihrer Privatsphäre und der Schutz Ihrer persönlichen Daten, der sog. personenbezogenen Daten ist uns ein wichtiges Anliegen. Im Folgenden informieren wir daher über die Erhebung personenbezogener Daten bei Nutzung unserer Website. Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar sind, z. B. Name, Adresse, E-Mail-Adressen, Nutzerverhalten.",
  processingActivities: "III. Verarbeitungstätigkeiten",
  operationWebsite: "1.	Betreiben der Website",
  provisionWebsite: "a.	Bereitstellung und Nutzung der Website",
  provisionDesc:
    "Für das Hostings unserer Webseite, Back-Up Leistungen und Speicherplatz nutzen wir den Service der STRATO AG (im Folgenden STRATO genannt). Im Rahmen einer Verarbeitung in unserem Auftrag erbringt die STRATO für diese Webseite die Dienste zum Hosting, Back-up, Sicherheit, Rechenkapazität und zur Darstellung der Webseite. Mit der STRATO haben wir einen Vertrag zur Auftragsverarbeitung abgeschlossen.",
  stratoDataPolicy:
    "Die Datenschutzerklärung der STRATO ist abrufbar unter: https://www.strato.de/datenschutz/",
  stratoDataPolicyDesc:
    "Im Allgemeinen ist es für die Nutzung der Internetseite www.safia.tech nicht erforderlich, dass Sie personenbezogene Daten angeben. Um die Zugriffs-Statistik auf die Internetseite zu erheben, werden durch die STRATO folgende Daten automatisch in Log-Files des Servers erhoben und gespeichert, die der Browser übermittelt.",
  dataBulletPointDesc: "Folgende Daten werden hierbei erhoben. Informationen:",
  dataBulletPointType: "über den Browsertyp und die verwendete Version",
  dataBulletPointSystem: "Das Betriebssystem des Nutzers",
  dataBulletPointIP: "Die IP-Adresse des Nutzers",
  dataBulletPointTime: "Datum und Uhrzeit des Zugriffs",
  dataBulletPointRefere:
    "Websites, von denen das System des Nutzers auf unsere Internetseite gelangt",
  dataBulletPointAccess:
    "Websites, die vom System des Nutzers über unsere Website aufgerufen werden",
  dataBulletPointStatusCode: "Statuscode des Aufrufs",
  dataBulletPointHTTPVersion: "HTTP Protokoll Version",
  dataBulletPointDataSize: "Datengröße beim Aufruf der Ressource",
  descSecurity:
    "Diese Verarbeitung ist technisch erforderlich, um Ihnen unsere Webseite anzeigen zu können. Wir nutzen die Daten auch, um die Sicherheit und Stabilität unserer Webseite zu gewährleisten. Die IP-Adressen werden zur Erkennung und Abwehr von Angriffen maximal sieben Tage gespeichert und anschließend anonymisiert oder gelöscht.",
  useOfCookies: "b. Verwendung von Cookies",
  useOfCookiesDesc2:
    "Bei Cookies handelt es sich um kleine Textdateien, die gespeichert werden und durch welche SAFIA Technologies GmbH bestimmte Informationen zufließen. Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen. Sie werden auf dem Rechner des Nutzers gespeichert und senden von diesen Informationen an unserer Seite. Daher haben Sie als Nutzer auch die volle Kontrolle über die Verwendung von Cookies. Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von Cookies deaktivieren oder einschränken.",
  useOfCookiesDesc3:
    "Cookies dienen jedoch dazu, das Internetangebot insgesamt nutzerfreundlicher und effektiver zu machen.",
  useOfCookiesDesc4:
    "Wir verwenden auf unserer Website darüber hinaus Cookies, die eine Analyse des Surfverhaltens der Nutzer ermöglichen (vgl. Webanalyse durch Matomo).",
  useOfCookiesDesc5:
    "Der Schutz Ihrer Daten ist uns jedoch wichtig! Deshalb haben wir alle Cookies so konfiguriert, dass ausschließlich eine SessionID gespeichert wird, welche nach 31 Tage automatisch gelöscht wird.",
  webAnalysisMatomo: "c.  Webanalyse durch Matomo",
  webAnalysisMatomoDesc1:
    "Zur statistischen Auswertung setzt SAFIA Technologies GmbH auf dieser Website „Matomo“ (früher“PIWIK“) ein. Das ist ein Open-Source-Tool zur Web-Analyse. Mit Matomo werden keine Daten an Server übermittelt, die außerhalb der Kontrolle von SAFIA Technologies GmbH liegen.Die Software verwendet Cookies (zu Cookies siehe bereits oben).",
  webAnalysisMatomoDesc2:
    "Zum Schutz Ihrer Daten haben wir Matomo zusätzlich so konfiguriert, dass Ihre IP-Adresse ausschließlich gekürzt erfasst wird. Wir verarbeiten Ihre personenbezogenen Nutzungsdaten daher anonymisiert. Ein Rückschluss auf Ihre Person ist uns nicht möglich. Weitere Informationen zu den Nutzungsbedingungen von Matomo und den datenschutzrechtlichen Regelungen finden Sie unter: https://matomo.org/privacy/.",
  legalBasis: "d. Rechtliche Grundlagen",
  legalBasisDesc:
    "Rechtsgrundlage für die Bereitstellung der Website www.safia.tech ist Art. 6 Abs. 1 lit. f) DS-GVO. Die Verarbeitung der genannten Daten ist für die Bereitstellung dieser Webseite erforderlich und dient damit der Wahrung eines berechtigten Interesses von SAFIA Technologies GmbH.",
  durationOfStorage: "e.  Dauer der Speicherung",
  durationOfStorageDesc:
    "Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung der Daten zur Bereitstellung der Website ist dies der Fall, wenn die jeweilige Sitzung beendet ist. Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens 90 Tagen der Fall. Eine darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer gelöscht oder verfremdet, sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.",
  appeal: "f. Widerspruchsmöglichkeiten",
  appealDesc1:
    "Mit Ausnahme der Webanalyse durch Matomo gemäß c. besteht keine Widerspruchsmöglichkeit.",
  appealDesc2:
    "Der Webanalyse durch Matomo gemäß c. kann jederzeit mit Wirkung für die Zukunft durch die untere Schaltfläche widersprochen werden.",
  contactFormAndEmail: "2.  Kontaktformular und E-Mail-Kontakt",
  contactFormAndEmailDesc1:
    "Die Kontaktaufnahme mit SAFIA Technologies GmbH ist über das bereitgestellte Kontaktformular möglich. Hierbei werden die in der Eingabemaske eingegeben Daten sowie Datum und Uhrzeit der Kontaktaufnahme an SAFIA Technologies GmbH übermittelt und gespeichert.",
  contactFormAndEmailDesc2:
    "Alternativ ist eine Kontaktaufnahme über die bereitgestellte E-Mail-Adresse möglich. In diesem Fall werden die mit der E-Mail übermittelten personenbezogenen Daten des Nutzers gespeichert.",
  contactFormAndEmailDesc3:
    "Die Verarbeitung der personenbezogenen Daten aus dem Kontaktformular oder E-Mail-Kontakt dient ausschließlich zur Bearbeitung der jeweiligen Anfrage.",
  contactFormAndEmailDesc4:
    "Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. f) DS-GVO. Die Verarbeitung der genannten Daten ist für die Beantwortung von Anfragen erforderlich und dient damit der Wahrung eines berechtigten Interesses von SAFIA Technologies GmbH.",
  contactFormAndEmailDesc5:
    "Die personenbezogenen Daten aus der Eingabemaske des Kontaktformulars und diejenigen, die per E-Mail übersandt wurden, werden gelöscht, wenn diese nicht mehr benötigt werden. Die während des Absendevorgangs zusätzlich erhobenen personenbezogenen Daten werden spätestens nach einer Frist von sieben Tagen gelöscht.",
  contactFormAndEmailDesc6:
    "Nimmt der Nutzer per E-Mail-Kontakt mit uns auf, so kann er der Speicherung seiner personenbezogenen Daten jederzeit widersprechen. In einem solchen Fall kann die Konversation nicht fortgeführt werden. Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme gespeichert wurden, werden in diesem Fall gelöscht.",
  emailDistributionList: "3.  E-Mail-Verteiler",
  emailDistributionListDesc1:
    "Auf unserer Internetseite ist ein Anmeldeformular vorhanden, welches genutzt werden kann, um sich in einem Newsletter-Datensystem einzutragen.",
  emailDistributionListDesc2:
    "Die hier eingetragenen personenbezogenen Daten werden zur Verarbeitung in einem Datensystem auf einem Server einer Dritten Partei, in diesem Fall dem Newsletter-Service-Provider „Sendinblue“ gespeichert.",
  emailDistributionListDesc3:
    "Auf die Weise erlangen die Verantwortlichen des Drittmittelprojekts SAFIA die Möglichkeit, manuell oder automatisch Information an eingetragene E-Mail-Adressen zuzustellen.",
  emailDistributionListDesc4: "Diese verarbeiteten Daten sind:",
  emailDistributionListLI1: "Anrede (optional)",
  emailDistributionListLI2: "Vorname (optional)",
  emailDistributionListLI3: "Nachname (optional)",
  emailDistributionListLI4: "E-Mail-Adresse",
  emailDistributionListDesc5:
    "Die Verarbeitung und Speicherung der personenbezogenen Daten aus der Eingabemaske dienen uns allein zur Speicherung der Daten in einem Newsletter-Backend-System für eine spätere automatische Übersendung von E-Mails an die eingetragene E-Mail-Adresse.",
  emailDistributionListDesc6:
    "Rechtsgrundlage für die Speicherung der Daten, die im Zuge einer Anmeldung zum Newsletter eingetragen werden, ist Art. 6 Abs. 1 lit. a DSGVO.",
  emailDistributionListDesc7:
    "Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes der automatisierten Zusendung von Information mittels E-Mail nicht mehr erforderlich sind. Dies ist der Fall, wenn die Laufzeit des Drittmittelprojekts SAFIA ausläuft und das Projekt als Ausgründung zu einer Kapitalgesellschaft nicht weitergeführt werden soll.",
  emailDistributionListDesc8: `Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur Speicherung der personenbezogenen Daten zu widerrufen. Dies kann der Nutzer über einen Abmeldelink erreichen, der am Ende einer über das Newsletter-Backend-System zugesendeten E-Mail zu finden ist. In diesem Fall wird die E-Mail-Adresse des Nutzers aus dem Newsletter-Backend-System ausgetragen und seine personenbezogenen Daten werden gelöscht. Weitere Informationen zu den Nutzungsbedingungen von „Sendinblue“ und den datenschutzrechtlichen Regelungen finden Sie unter: https://de.sendinblue.com/legal/privacypolicy/.`,
  rightsDataSubject: "IV. Rechte der betroffenen Person",
  rightsDataSubjectDesc1:
    "Sie haben gegenüber der SAFIA Technologies GmbH folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten: ",
  rightsDataSubjectLI1:
    "Recht auf Widerruf der Einwilligung, Art. 13 und 14 DSGVO ",
  rightsDataSubjectLI2: "Recht auf Auskunft - Art. 15 DSGVO",
  rightsDataSubjectLI3: "Recht auf Berichtigung - Art. 16 DSGVO",
  rightsDataSubjectLI4: "Recht auf Löschung - Art. 17 DSGVO",
  rightsDataSubjectLI5:
    "Recht auf Einschränkung der Verarbeitung - Art. 18 DSGVO",
  rightsDataSubjectLI6: "Recht auf Datenübertragbarkeit - Art. 20 DSGVO",
  rightsDataSubjectLI7: "Recht auf Widerspruch - Art. 21 DSGVO",
  rightsDataSubjectDesc2:
    "Diese Rechte können Sie gegenüber dem unter A genannten Verantwortlichen geltend machen.",
  rightsDataSubjectDesc3:
    "Ihnen steht zudem gemäß Art. 77 DSGVO ein Beschwerderecht bei der datenschutzrechtlichen Aufsichtsbehörde, dem Bundesbeauftragten für den Datenschutz und die Informationsfreiheit (BfDI), zu.",
  rightsDataSubjectDesc4:
    "Sie können sich mit Fragen und Beschwerden auch an den unter B genannten Datenschutzbeauftragten in der BAM wenden.",
  rightsDataSubjectDesc5:
    "Weitere Informationen zum Datenschutz finden Sie auf der Datenschutzerklärung der BAM unter https://www.bam.de/Navigation/DE/Services/Datenschutz/datenschutz.html",
  weAreHere: "Wir sind hier",
  newslettersuccess:
    "Wir haben Ihnen einen Bestätigungslink per E-Mail zugesendet.",
  newslettererror: "Ups, es gab einen Fehler. Bitte versuchen Sie es erneut.",
  isLoading: "sendet ...",
  isLoadingNewsletter: "abonniert ...",
  newsletterconfirm: "Sie haben sich erfolgreich zum Newsletter angemeldet",
  newslettersuccessseo: "Newsletteranmeldung erfolgreich",
  error404desc:
    "Tut uns Leid, aber die gewünschte Seite konnte leider nicht gefunden werden!",
  divers: "Divers",
  noSimilarReports: "Es gibt keine ähnliche Beiträge.",
  understand: "Alles klar",
  cookieText:
    "Um Ihnen das beste Nutzererlebnis zu bieten, verwenden wir Cookies. Indem Sie diese Webseite benutzen, stimmen Sie unseren",
  datenschutzrichtlinien: "Datenschutzrichtlinien",
  cookieText2: "zu.",
  umweltanalytikHref: "Umweltanalytik",
  schimmelpilzgifteHref: "Schimmelpilzgifte",
  allergenenHref: "Allergenen",
  pressReleaseEmpty: "Aktuell gibt es noch keine Pressemitteilungen.",
  blogEmpty: "Aktuell gibt es noch keine Berichte.",
  videos: "Videos",
  ceo: "Geschäftsführer",
  timeline: {
    first: {
      title: "Beginnn der\nForschung",
      description:
        "Eine EXIST-Förderung unterstützt die Entwicklung eines die Mykotoxin-Kits(Verknüpfung zu Mykotoxinen) auf Grundlage bisheriger Forschung für die Umwelt-und Abwasseranalytik (Verknüpfung zu Umweltanalytik)",
    },
    second: {
      title: "Fertigstellung\ndes Prototypen",
      description:
        "Ab Apr 2022 sollen die FuE-Arbeiten an dem SAFIA Mykotoxin-Kit beendet und der Prototyp für die Pilotierungphase bereitstehen.",
    },
    third: {
      title: "Markteintritt Mykotoxin-Kit",
      description:
        "Der Prototyp soll bis Q4 2022 finalisiert werden und ab diesem Zeitpunkt kommerziell erhältlich sein.",
    },
    fourth: {
      title: "Weiterentwicklung SAFIA Plattform",
      description:
        "Ab Q2 2023 soll das Applikationssprektrum von SAFIA erweitert werden. Dem SAFIA Kit werden weitere Mykotoxine hinzugefügt.",
    },
    fifth: {
      title: "Markteintritt erweitertes Mykotoxin-Kit",
      description:
        "Ende 2024 werden Mykotoxin-Kits für ein noch breiteres Anwendungsfeld auf den Markt gebracht.",
    },
    sixth: {
      title: "Beginn\nF&E Allergen-Kit",
      description:
        "In Anschluss an den Market-Launch der Mykotoxin-Kits sollen die FuE Arbeiten für ein SAFIA Allergen-Kit beginnen.",
    },
  },
};
